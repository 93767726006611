import jQuery from 'jquery';
import select2 from "select2";

import './bootstrap';
import "../m/core/index";
import "../m/app/layouts/applicationscripts.js";

import './uiHandler/core.js';
import './websocket/app.js';
import './websocket/client.js';

import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-dt';
import 'datatables.net-autofill-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';
import 'datatables.net-colreorder-dt';
import 'datatables.net-fixedcolumns-dt';
import 'datatables.net-fixedheader-dt';
import 'datatables.net-keytable-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-rowgroup-dt';
import 'datatables.net-rowreorder-dt';
import 'datatables.net-scroller-dt';
import 'datatables.net-searchbuilder-dt';
import 'datatables.net-searchpanes-dt';
import 'datatables.net-select-dt';
import 'datatables.net-staterestore-dt';
import flatpickr from "flatpickr";
import Alpine from 'alpinejs';
import {observer, observerTargetHTML, observerTargetoutputViewController} from "./uiHandler/core.js";

DataTable.use(DataTablesCore);
window.DataTable = DataTable;
window.$ = window.jQuery = jQuery;

// Select2 explizit registrieren
window.jQuery.fn.select2 = select2;
window.$.fn.select2 = select2;

// Flatpickr explizit an jQuery anhängen
window.jQuery.fn.flatpickr = function (options) {
    return this.each(function () {
        flatpickr(this, options);
    });
};

console.log('jQuery Version:', $.fn.jquery);
console.log('Select2 verfügbar nach Import?', typeof $.fn.select2 !== 'undefined');

window.Alpine = Alpine;

Alpine.start();

$(document).ready(function () {
    let observerConfig1 = {'attributes': "data-bs-theme"};
    let observerConfig2 = {'attributes': "data-module-url"};

    observer.observe(observerTargetHTML, observerConfig1);
    observer.observe(observerTargetoutputViewController, observerConfig2);

    $('body').on('click', 'a, button', function(event) {
        handleClick(event);
    });
});

